.login-page {
    background-image: var(--background-image-mobile);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    align-items: center;
    overflow-x: hidden;
    
    @media (min-width: 768px) {
      .login-page {
        background-image: var(--background-image);
      }
    }
}

 .signup-logotipo {
  display: flex;
  width: 290px;
  height: auto;
  padding: 8px 4px 8px 4px;
  justify-content: center;
  align-items: center;
}

.logomarca {
  width: 250px;
  height: auto;
}

.signup-logomarca {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 10px;
}
/* Tabela Cursos */

.styled-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0;
    font-size: 18px;
    text-align: left;
  }
  
  .styled-table th,
  .styled-table td {
    padding: 12px 15px;
    border-bottom: 1px solid #ddd;
  }
  
  .styled-table th {
    background-color: #f2f2f2;
  }
  
  .edit-mode {
    background-color: #f8f8f8;
  }
  
  .edit-btn,
  .update-btn,
  .cancel-btn {
    padding: 8px 12px;
    margin-right: 5px;
    cursor: pointer;
  }
  
  .edit-btn {
    background-color: #4caf50;
    color: white;
    border: none;
  }
  
  .update-btn {
    background-color: #008cba;
    color: white;
    border: none;
  }
  
  .cancel-btn {
    background-color: #d9534f;
    color: white;
    border: none;
  }
  
  
  .div-input-group {
    display: flex;
    width: 100%;
    gap: 4; 
    flex-direction: column;
  }
  
  .div-input-field {
    display: flex;
    width: 100%;
    gap: 2; 
    border-radius: 8px;
    border: 1px solid black;
    padding: 5px
  }
  
  .div-input-label {
    font-weight: bold;
  }


  .lessons-classes-container {
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;
    width: 90% !important;
    align-items: flex-start !important;
    justify-content: center;

    .new-lessons {
      margin: 0 2% !important;
    }
}
/* Tabela Cursos */

.styled-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0;
    font-size: 18px;
    text-align: left;
  }
  
  .styled-table th,
  .styled-table td {
    padding: 12px 15px;
    border-bottom: 1px solid #ddd;
  }
  
  .styled-table th {
    background-color: #f2f2f2;
  }
  
  .edit-mode {
    background-color: #f8f8f8;
  }
  
  .edit-btn,
  .update-btn,
  .cancel-btn {
    padding: 8px 12px;
    margin-right: 5px;
    cursor: pointer;
  }
  
  .edit-btn {
    background-color: #4caf50;
    color: white;
    border: none;
  }
  
  .update-btn {
    background-color: #008cba;
    color: white;
    border: none;
  }
  
  .cancel-btn {
    background-color: #d9534f;
    color: white;
    border: none;
  }
  
  
  .div-input-group {
    display: flex;
    width: 100%;
    gap: 4; 
    flex-direction: column;
  }
  
  .div-input-field {
    display: flex;
    width: 100%;
    gap: 2; 
    border-radius: 8px;
    border: 1px solid black;
    padding: 5px
  }
  
  .div-input-label {
    font-weight: bold;
  }

  .card-container-users {
    width: 80%;
    align-items: center;
    display: flex;
    flex-direction: column;
}
  
  .new-simulado {
    width: 100%;
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .create-simulados-list {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 10px;
  
    ul {
      background-color: #1a19199a;
      padding: 1rem;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      
      li {
        list-style: none;
        display: flex;
        align-items: center;
        gap: 1rem;
        border: 1px dotted #ffffff9d;
        padding: 1rem;
        justify-content: space-between;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
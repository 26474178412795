.student-page {
    background-image: var(--background-image-mobile-student);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    align-items: center;
    overflow-x: hidden;
    color: #fff;

    @media (min-width: 768px) {
        background-image: var(--background-image-student);
        background-size: 100% 100%;
    }

    &_container {
        display: flex;
        flex-direction: column;
        min-height: 100vh;

        &-main {
            display: flex;
            flex: 1;
            flex-direction: column;
            margin: 0 auto;
            width: 90%;
            background-color: #121214a2;
            border-radius: 10px;

            @media (min-width: 992px) {
                flex-direction: row;
                width: 85%;
            }

            &.concursos {
                background-color: #edf0f1af;
            }
        }
    }

    &_novideo {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        padding: 0 6px;

        @media (min-width: 992px) {
            //flex-direction: row;
        }

        .img-enem {
            width: 70%;

            @media (min-width: 992px) {
                width: 100%;
                max-width: 700px;
            }
        }

        &-description {
            text-align: justify;
            margin-top: 1rem;
            margin-bottom: 1rem;
            padding: 0 1rem;
        }

        &-sub-description {
            display: flex;
            justify-content: center;
            flex-direction: column;

            @media (min-width: 992px) {
                flex-direction: row;
            }
        }

        .sub-description_container {
            margin: 0 auto;
            padding: 2rem;

            .title {
                font-size: 2rem;
                font-weight: bold;
                margin-bottom: 1rem;
            }

            .list {
                list-style-type: disc;
                padding-left: 1.5rem;
            }

            .pendulum {
                display: flex;
                justify-content: center;
                margin-top: 5rem;
                
            }
        }

        &.concursos {
            color: #000000;
        }
    }
}

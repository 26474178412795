.login-component {
  display: flex;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px #333;
  width: 100%;
  height: 100%;
  max-width: 350px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  padding: 20px 10px;

    // form {
    //   display: flex;
    //   flex-direction: column;
    //   max-width: 300px;
    //   margin: 0 auto;
  
    //   label {
    //     margin-bottom: 8px;
    //   }
  
    //   input {
    //     margin-bottom: 16px;
    //     padding: 8px;
    //   }
  
    //   button {
    //     padding: 10px;
    //     background-color: #007bff;
    //     color: #fff;
    //     cursor: pointer;
    //   }
    // }

    // p {
    //     margin-top: 20px;
    //     font-size: 14px;
    //     color: #333;
    
    //     a {
    //       color: #007bff;
    //       text-decoration: none;
    //       font-weight: bold;
    
    //       &:hover {
    //         text-decoration: underline;
    //       }
    //     }
    //   }
}


.signup-login {
  // height: 100%;
  // min-height: 100vh;
  // display: flex;
  // justify-content: center;
  // flex-direction: column;
  // align-items: center;
  // background: rgba(255, 255, 255, 0.1);
  // //border-right: 1px solid white;
  // width: 100%;
  // max-width: 484px;
  // margin: 0;
  // flex: 1;
}





.signup-form {
  padding: 20px;
  margin: 0;

}
@media (min-width: 768px) {
  .signup-form {
    margin: 5px;
  }
}

.buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-login, .button-forgot {
  margin-bottom: 20px;
  width: 100%;
  max-width: 200px;
  background-color: #ff523a;
  border: none;
}

.button-signup {
  width: 100%;
  max-width: 200px;
  background-color: #000;
  border: none;
}

.button-signup-cadastro {
  width: 100%;
  max-width: 200px;
  background-color: #000;
  border: none;
  margin-bottom: 20px;
}

.button-signup-cadastro-back {
  width: 100%;
  max-width: 200px;
  background-color: #000;
  border: none;
}

.button-login:hover, .button-signup-cadastro-back:hover {
  background-color: #ff523a;
  border: none;
}

.button-signup:hover, .button-signup-cadastro:hover {
  background-color: #ff523a;
  border: none;
}



.input-group {
  position: relative;
}

.input-group-text {
  position: absolute;
  right: 5px;
  top: 5px;
  /*transform: translateY(-50%);*/
  background-color: transparent !important;
  border: none !important;
  cursor: pointer;
  z-index: 99;
}

.user-icon {
  cursor: auto;
}

.form-control {
  padding-right: 30px; /* Adicione um espaço para o ícone */
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid black;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;
}

.form-control:focus {
  box-shadow: none;
  border-color: black;
  background-color: transparent;
}

/* .recaptcha-container div div {
  width: 100% !important;
  margin-bottom: 10px !important;
  border-radius: 8px !important;
}

.recaptcha-container div div div iframe {
  width: 100% !important;
} */
  
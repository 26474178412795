.simulado-page {
    //background-image: url('../../../assets/images/black-background.jpg');
    background-image: var(--background-image-mobile-student);
    background-size: cover;
    background-repeat: no-repeat;
    //background-position: center center;
    width: 100%;
    height: 100%;
    /*min-height: 100vh;*/
    align-items: center;
    overflow-x: hidden;
    color: #fff;

    @media (min-width: 768px) {
        background-image: var(--background-image-student);
        background-size: 100% 100%;
    }

    &_container {
        .title-simulados {
            color: #fff;
            text-align: center;
        }
    }
}

.simulado-video-page {
    &_container-description {
        
        .buttons-footer {
            display: flex;
            width: 100%;
            margin-top: 0;
            margin-left: auto;
            margin-right: auto;

            @media (min-width: 768px) {
                width: 50%;
                
            }

            .material-button {
                flex-direction: column;
                
                @media (min-width: 768px) {
                    flex-direction: row;
                }

                &_icon {
                    justify-content: center;
                }

                &_info {
                    @media (max-width: 768px) {
                        padding: 0 2rem;
                        text-align: center;
                    }
                }

                .separador {
                    @media (max-width: 768px) {
                        display: none;
                    }
                }
            }
        }
    }
}


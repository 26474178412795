.pendulum-card-landing_page {
    width: 200px;
    height: 200px;
    background-color: #0000E7;
    color: white;
    border-radius: 10px;
    position: fixed;
    top: 60px;
    right: 80px;
    animation: pendulum 2s infinite alternate ease-in-out;
  }
  
  .circle-landing_page {
    width: 15px;
    height: 15px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: -7.5px;
    left: calc(50% - 7.5px);
  }
  
  .pendulum-content-landing_page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .pendulum-text-landing_page {
    font-size: 40px;
    font-weight: bold;
  }
  
  .pendulum-amount-landing_page {
    font-size: 70px;
    margin-top: 10px;
    font-weight: bold;
  }
  
  .flashing-text-landing_page {
    animation: flashing 1.5s infinite;
  }
  
  .yellow-text-landing_page {
    color: #F0C932;
  }
  
  @keyframes pendulum {
    0% {
      transform: rotate(10deg);
    }
    100% {
      transform: rotate(-10deg);
    }
  }
  
  @keyframes flashing {
    0% {
      color: white;
    }
    50% {
      color: #F0C932;
    }
    100% {
      color: white;
    }
  }
  
  @media (max-width: 768px) {
    .pendulum-card-landing_page {
      position: static;
      width: 150px;
      height: 120px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  
    .pendulum-amount-landing_page {
      font-size: 35px;
    }
  
    .pendulum-text-landing_page {
      font-size: 25px;
    }
  }
  

  .pendulum-card-info {
    width: 200px;
    height: 120px;
    background-color: #0000E7;
    color: white;
    border-radius: 10px;
    position: relative;
    /*top: 60px;
    right: 80px;*/
    animation: pendulum 2s infinite alternate ease-in-out;
  }
  
  .circle-info {
    width: 15px;
    height: 15px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: -7.5px;
    left: calc(50% - 7.5px);
  }
  
  .pendulum-content-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .pendulum-text-info {
    font-size: 20px;
    font-weight: bold;
  }
  
  .pendulum-amount-info {
    font-size: 30px;
    margin-top: 10px;
    font-weight: bold;
  }
  
  .flashing-text-info {
    animation: flashing 1.5s infinite;
  }
  
  .yellow-text-info {
    color: #F0C932;
  }
  
  @keyframes pendulum {
    0% {
      transform: rotate(10deg);
    }
    100% {
      transform: rotate(-10deg);
    }
  }
  
  @keyframes flashing {
    0% {
      color: white;
    }
    50% {
      color: #F0C932;
    }
    100% {
      color: white;
    }
  }
  
  @media (max-width: 768px) {
    .pendulum-card-info {
      position: static;
      width: 150px;
      height: 120px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  
    .pendulum-amount-info {
      font-size: 20px;
    }
  
    .pendulum-text-info {
      font-size: 15px;
    }
  }
  
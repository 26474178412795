.lesson {
    text-decoration: none;

    .lesson-card {
        border-radius: 8px;
        border: 1px solid #323238;
        padding: 1rem;
        margin-top: .5rem;
    
        &:hover {
            border-color: #81D8F7CC;
        }
    
        &-blue {
            background-color: #00008B;
        }

        &_header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;

            &-available {
                font-size: .875rem;
                line-height: 1.25rem;
                font-weight: 500;
                display: flex;
                align-items: center;
                gap: 2px;
                color: #81D8F7CC;
            }

            .text-blue-500 {
                color: #81D8F7CC;
            }

            .text-white {
                color: #fff;
            }

            &-unavailable {
                font-size: .875rem;
                line-height: 1.25rem;
                font-weight: 500;
                color: #FBA94C;
                display: flex;
                align-items: center;
                gap: 2;
            }
        }

        &_type {
            font-size: .75rem;
            line-height: 1rem;
            padding-top: .125rem;
            padding-bottom: .125rem;
            padding-left: .5rem;
            padding-right: .5rem;
            color: #fff;
            border-radius: .25rem;
            border: 1px solid;
        }

        .border-white {
            border-color: #fff;
        }

        .border-blue-700 {
            border-color: #81D8F7CC
        }

        &_title {
            margin-top: 1.25rem;
            display: block;
            font-weight: bolder;
            color: #C4C4CCB3;
            
            &.text-white{
                color: #fff;
            }
        }
    }
}
.wait-lesson {
    flex: 1;
}

.video-page {
    flex: 1;

    &_container {
        background-color: #000;
        display: flex;
        justify-content: center;
    }

    &_player {
        height: 100%;
        width: 100%;
        max-width: 1600px;
        aspect-ratio: 16 / 9;
    }

    &_container-description {
        padding: 2rem;
        max-width: 1600px;
        margin: auto;

        &_container {
            display: flex;
            gap: 16px;
            flex-direction: column;
            align-items: center;

            @media (min-width: 768px) {
                align-items: flex-start;
                flex-direction: row;
            }

            .title {
                flex: 1;

                h1 {
                    font-size: 1.5rem;
                    line-height: 2rem;
                    font-weight: bold;
                }
                
                .description {
                    line-height: 1.625;
                    margin-top: 1rem;
                    color: #C4C4CC;
                }

                .teacher-container {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    margin-top: 1.5rem;

                    &_photo {
                        width: 4rem;
                        height: 4rem;
                        border-radius: 100%;
                        border: 2px solid #fff;
                    }
                }

                .leading-relaxed {
                    line-height: 1.625;

                    .teacher-name {
                        font-weight: 700;
                        font-size: 1.5rem;
                        line-height: 2rem;
                        display: block;
                    }

                    .teacher-bio {
                        font-size: .875rem;
                        line-height: 1.25rem;
                        color: #C4C4CC;
                        display: block;
                    }
                }

                &.concursos {
                    color: #000000;

                    .description {
                        color: #000000;
                    }

                    .leading-relaxed {
                        .teacher-bio {
                            color: #000000;
                        }
                    }
                }
            }


            .buttons-top_container {
                display: flex;
                flex-direction: column;
                gap: 1rem;

                .discord-button {
                    display: flex;
                    align-items: center;
                    padding: 1rem;
                    background-color: #00008B;
                    transition-property: color,background-color,border-color,text-decoration-color,fill,stroke;
                    transition-timing-function: cubic-bezier(.4,0,.2,1);
                    transition-duration: .15s;
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: .875rem;
                    line-height: 1.25rem;
                    border-radius: .25rem;
                    gap: .5rem;
                    justify-content: center;
                    color: inherit;
                    text-decoration: none;

                    &:hover {
                        background-color: #0000E7;
                    }

                    &.concursos {
                        display: none;
                    }
                }

                .desafio-button {
                    display: flex;
                    align-items: center;
                    padding: 1rem;
                    transition-property: color,background-color,border-color,text-decoration-color,fill,stroke;
                    transition-timing-function: cubic-bezier(.4,0,.2,1);
                    transition-duration: .15s;
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: .875rem;
                    line-height: 1.25rem;
                    border-radius: .25rem;
                    gap: .5rem;
                    justify-content: center;
                    color: inherit;
                    text-decoration: none;
                    color: #81D8F7;
                    border: 1px solid #81D8F7;

                    &:hover {
                        background-color: #0000E7;
                        color: #09090A;
                    }

                    &.concursos {
                        background-color: #00008B;
                        transition-property: color,background-color,border-color,text-decoration-color,fill,stroke;
                        transition-timing-function: cubic-bezier(.4,0,.2,1);
                    }
                }
            }
        }

        .buttons-footer {
            display: grid;
            gap: 2rem;
            width: 100%;
            margin-top: 5rem;
            grid-template-columns: repeat(1,minmax(0,1fr));
            
            @media (min-width: 768px) {
                grid-template-columns: repeat(2,minmax(0,1fr));
            }

            .material-button {
                display: flex;
                background-color: #121214;
                border-radius: 0.25rem;
                overflow: hidden;
                align-items: stretch;
                gap: 1.5rem;
                transition-property: color,background-color,border-color,text-decoration-color,fill,stroke;
                transition-timing-function: cubic-bezier(.4,0,.2,1);
                transition-duration: .15s;
                color: inherit;
                text-decoration: inherit;

                &:hover {
                    background-color: #29292E;
                }

                &_icon {
                    padding: 1.5rem;
                    display: flex;
                    align-items: center;
                    height: 100%;
                    background-color: #0000E7;
                }

                &_info {
                    line-height: 1.625;
                    padding-top: 1.5rem;
                    padding-bottom: 1.5rem;

                    &-title {
                        font-size: 1.5rem;
                        line-height: 2rem;
                        font-weight: bolder;
                    }
    
                    &-desc {
                        font-size: .875rem;
                        line-height: 1.25rem;
                        color: #C4C4CC;
                        margin-top: .5rem;
                    }
                }

                .separador {
                    padding: 1.5rem;
                    align-items: center;
                    height: 100%;
                    display: flex;

                    @media (max-width: 768px) {
                        display: none;
                    }
                }


            }
        }
    }
}

.sidebar-container {
    background-color: #121214;
    padding: 1.5rem;
    border-left: 1px solid #29292E;
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
    width: 100%;
    height: 100%;
    max-height: 190vh;
    overflow-y: auto;

    @media (min-width: 768px) {
        height: 190vh;
        width: 348px;
    }

    &.concursos {
        background-color: #01273d;
    }

    &_title {
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 2rem;
        padding-bottom: 1.5rem;
        margin-bottom: 1.5rem;
        border-bottom: 1px solid #323238;
        display: block;
        text-align: center;
    }

    &_card {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
}
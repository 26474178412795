.home-page {
    background-image: var(--background-image-mobile);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    align-items: center;
    overflow-x: hidden;

    &_title {
        font-size: 3rem;
        font-weight: bold;
        text-transform: uppercase;

        @media (max-width: 768px) {
            font-size: 2.5rem;
        }
    }
    
    @media (min-width: 768px) {
        background-image: var(--background-image);
    }

    
    &_elements {
        &-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
            gap: 30px;
            width: 100%;

            @media (max-width: 768px) {
                flex-direction: column;
            }

            &_items {
                width: 100%;
                max-width: 400px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                &-title {
                    font-size: 1.2rem;
                }

                img {
                    @media (max-width: 768px) {
                        width: 150px;
                        height: auto;
                    }
                }
            }
        }


        
    }
}
